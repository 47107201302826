<template>
  <div
    ref="pdf-report-editor"
    class="pdf-report-editor">
    <div class="editor-page-header">
      <router-link :to="{ name: 'email_report_builder' }">
        <b-button
          type="is-light"
          class="back-btn mr-3"
          icon-right="arrow-left" />
      </router-link>

      <div class="report-breadcrumbs">
        <div>
          <h4>Report</h4>
          <h2>{{ reportData?.name || "Pdf Report" }}</h2>
        </div>

        <!-- <div class=" arrow-right"> <i class="material-icons">chevron_right </i></div>

        <div>
          <h4>Report</h4>
          <h2>Confg Pdf Report</h2>
        </div> -->
      </div>

      <div class="control-bar">
        <router-link
          :to="{
            name: 'pdf_report_settings',
            params: { report_id: pdfReportID },
          }">
          <button class="button mr-3">
            Report Settings
          </button>
        </router-link>
        <a-button
          class="mr-3"
          hotkeys="ctrl+backspace"
          type="is-outlined"
          :disabled="!isDirty"
          @click="discardChanges">
          Discard Changes
        </a-button>
        <a-button
          type="is-primary"
          hotkeys="ctrl+s"
          :disabled="!isDirty"
          @click="saveEdits">
          Save Changes
        </a-button>
      </div>
    </div>

    <div class="columns editor-body">
      <div class="column options-column">
        <div class="block library">
          <div class="block-header">
            <h4 class="">
              Modules
            </h4>
          </div>

          <div class="block-body pt-3">
            <vuedraggable
              :clone="cloneModuleCard"
              :list="Object.values(moduleOptions)"
              :sort="false"
              class="library-items"
              :group="{ name: 'moduleCards', pull: 'clone', put: false }">
              <div
                v-for="(item, i) in moduleOptions"
                :key="i">
                <div
                  v-if="!item.hideInLibrary"
                  class="miniview-item">
                  <img
                    :src="item.icon"
                    alt="" />
                  <div>{{ item.name }}</div>
                </div>
              </div>
            </vuedraggable>
          </div>
        </div>
      </div>
      <div class="column">
        <splitpanes class="default-theme">
          <pane
            min-size="20"
            class="pane">
            <div class="block layout">
              <div class="block-header">
                <h4 class="">
                  Layout
                </h4>
              </div>

              <div class="block-body">
                <div class="dropzone-container">
                  <vuedraggable
                    group="moduleCards"
                    ghost-class="ghost"
                    class="dropzone"
                    :list="reportModules"
                    @change="createModule"
                    @remove="makeInputsDirty">
                    <div
                      v-for="(module, i) in reportModules"
                      :key="i">
                      <MiniView
                        :data="module"
                        @delete="removeReport(i)"
                        @duplicate="duplicateModule(i)" />
                    </div>
                  </vuedraggable>
                  <div
                    v-if="reportModules.length === 0"
                    class="dropzone-placeholder" />
                </div>

                <!-- <button
                  class="mini-view-item add-row-button"
                  @click="showModulePicker(false)">
                  <img
                    src="@/assets/icons/add-row.svg"
                    alt="" />

                  <div>Add Object</div>
                </button> -->
              </div>
            </div>
          </pane>
          <pane min-size="20">
            <PreviewAndJSONEditor
              v-model="reportModules"
              :full-report="reportData"
              type="report" />
          </pane>
        </splitpanes>
      </div>
    </div>

    <b-loading
      v-model="isLoading"
      :is-full-page="true"
      :can-cancel="true" />

    <!-- <b-modal
      v-model="modulePickerModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      close-button-aria-label="Close"
      aria-modal>
      <form action="">
        <div class="modal-card select-module-modal">
          <header class="modal-header">
            <h2>Add Object</h2>
            <button
              type="button"
              class="delete"
              @click="modulePickerModal = false" />
          </header>
          <div class="modal-body">
            <div
              v-for="item in moduleOptions"
              :key="item.type">
              <div
                v-if="!item.hideInLibrary"
                class="miniview-item"
                @click="addTrackerModuleBelow(item)">
                <img
                  :src="item.icon"
                  alt="" />

                <div>{{ item.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal> -->
  </div>
</template>

<script>
  import vuedraggable from 'vuedraggable'
  import uniqid from 'uniqid'
  import MiniView from '@/components/pdf_report/MiniViewModule.vue'
  import PreviewAndJSONEditor from '@/components/pdf_report/PreviewAndJsonEditor.vue'
  import { Splitpanes, Pane } from 'splitpanes'

  export default {
    name: 'PdfReportEditor',
    components: {
      vuedraggable,
      MiniView,

      Splitpanes,
      Pane,
      PreviewAndJSONEditor,
    },

    beforeRouteLeave (from, to, next) {
      if (this.isDirty) {
        this.$buefy.dialog.alert({
          title: 'Unsaved Changes',
          message: 'Please save changes before leaving this page',
          onConfirm: () => {},
        })
      } else {
        next()
      }
    },
    props: {},
    data () {
      return {
        pdfReportID: null,
        isLoading: true,
        isDirty: false,
        reportModules: [],
        reportData: {},

        selectedModule: {},
        selectedIndex: null,

        modulePickerModal: false,
      }
    },
    computed: {
      moduleOptions () {
        return {
          TableModule: {
            name: 'Table',
            type: 'TableModule',
            icon: require('@/assets/icons/table.svg'),
            hasFullScreenEditor: true,
          },
          TrackerTableModule: {
            name: 'Tracker Table',
            type: 'TrackerTableModule',
            icon: require('@/assets/icons/tracker-table.svg'),
            hasFullScreenEditor: true,
          },
          TitleModule: {
            name: 'Title',
            type: 'TitleModule',
            icon: require('@/assets/icons/page-header.svg'),
          },

          SectionDividerModule: {
            name: 'Section Divider',
            type: 'SectionDividerModule',
            icon: require('@/assets/icons/section-divider.svg'),
          },

          PageBreakModule: {
            name: 'Page Break',
            type: 'PageBreakModule',
            icon: require('@/assets/icons/page-break.svg'),
          },
        }
      },
    },
    watch: {
      reportModules: {
        handler () {
          this.isDirty = true
        },
        deep: true,
      },
    },

    async mounted () {
      this.pdfReportID = this.$route.params.report_id
      await this.loadPdfReportData()
      this.isLoading = false

      this.$store.state.temp.companyMeasures =
        await this.$apis.companyConfigs.getCompanyMeasures()

      this.$store.state.temp.companyDimensions =
        await this.$apis.companyConfigs.getCompanyDimensions()

      this.$store.state.temp.companyExpressions =
        await this.$apis.companyConfigs.getCompanyExpressions()

      await this.$store.dispatch('app/reportsConfig/refreshOptions')

      window.addEventListener('keydown', this.shortcutHandler)
    },
    beforeDestroy () {
      window.removeEventListener('keydown', this.shortcutHandler)
    },
    methods: {
      async createModule (module) {
        if (module.added) {
          const response = await this.$apis.pdfReports.createPdfReportModule(
            module.added.element
          )

          //find and replace the module with the new one
          const index = this.reportModules.findIndex(
            (obj) => obj.key === module.added.element.key
          )

          this.$set(this.reportModules, index, response)

          this.makeInputsDirty()
        }
      },

      showModulePicker () {
        this.modulePickerModal = true
      },
      addTrackerModuleBelow (type) {
        this.reportModules.splice(
          this.reportModules.length,
          0,
          this.cloneModuleCard(type)
        )

        this.modulePickerModal = false
      },

      cloneModuleCard (module) {
        if (module.type === 'TrackerTableModule') {
          return {
            key: uniqid(),
            type: 'TrackerTableModule',
            name: 'Tracker Table',
            tracker_rows: [],
            tracker_table_style: {
              show_header: false,
            },
            tracker_total_headers: [],
            time_dimension: {
              type: 'CompanyDimensionRef',
              key: 'date',
            },
            tracker_pivot: null,
          }
        }

        if (module.type === 'TableModule') {
          return {
            key: uniqid(),
            title: 'TableModule',
            type: 'TableModule',
            table_style: {
              space_before: 16,
              space_after: 32,
            },
            table_dimensions: [],
            measure_groups: [],
            table_pivot: null,
            filters: [],
          }
        }

        return {
          name: module.name,
          type: module.type,
          title: '',
          key: uniqid(),
        }
      },

      removeReport (index) {
        this.isDirty = true
        if (index > -1) {
          this.reportModules.splice(index, 1)
        }
        this.selectedModule = {}
      },

      makeInputsDirty () {
        this.isDirty = true
      },

      async duplicateModule (index) {
        this.isDirty = true
        await this.$apis.pdfReports.duplicatePdfReportModule(
          this.reportModules[index].id,
          {
            title:
              this.reportModules[index].title ||
              this.moduleOptions[this.reportModules[index].type].name + ' (Copy)',
          }
        )
        await this.loadPdfReportData()
      },

      async loadPdfReportData () {
        this.isLoading = true
        const response = await this.$apis.pdfReports.getPdfReportData(
          this.pdfReportID
        )
        if (response) {
          this.reportData = response
          this.reportModules = response.modules

          document.title = `${this.reportData.name} - Ingest`
        }
        await this.$nextTick()
        this.isDirty = false
        this.isLoading = false
      },

      async discardChanges () {
        await this.loadPdfReportData()
      },

      async saveEdits () {
        this.isLoading = true
        const requestBody = {
          ...this.reportData,
          modules: this.reportModules,
        }

        const response = await this.$apis.pdfReports.updatePdfReport(
          this.pdfReportID,
          requestBody
        )
        if (!response) {
          this.$buefy.toast.open({
            message: `Error Saving Data`,
            type: 'is-warning',
          })
        } else {
          this.isDirty = false
        }

        this.isLoading = false
      },

      onError (e) {
        console.log('error', e)
      },
    },
  }
</script>

<style lang="scss" scoped>
.report-breadcrumbs {
  display: flex;

  h2 {
    font-weight: 400;
    font-size: 20px;
    margin: 0;
  }

  h4 {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
    color: $grayscale-3;
  }

  .arrow-right {
    display: flex;
    align-items: center;
    margin: 0 10px;
    font-size: 20px;
    color: #b0b0b0;
  }
}

.pdf-report-editor {
  height: calc(100vh - 95px) !important;
  padding: 10px 30px 0;
  display: flex;
  flex-direction: column;
  margin: auto;
  overflow: hidden;

  .editor-page-header {
    display: flex;
    align-items: center;
    margin-top: 1px;
    padding-bottom: 15px;

    .back-btn {
      font-size: 24px;
      border-radius: 100%;
    }

    h2 {
      font-weight: 400;
      font-size: 22px;
    }

    .control-bar {
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .editor-body {
    margin: 0 !important;
    height: calc(100% - 80px);
    .options-column {
      max-width: 250px;
    }

    .pane {
      background-color: white;
    }
    .block {
      margin-bottom: 6px;
      border: $border-1;
      border-radius: 3px;
      background-color: white;
      height: 100%;
      flex-direction: column;
      display: flex;

      .block-header {
        min-height: 40px;
        align-items: center;
        padding: 0 5px;
        border-bottom: $border-1;
        background-color: white;
        display: flex;

        h4 {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          padding: 0 10px;
          text-transform: capitalize;
          margin: 0;
          color: black;
        }
      }
      .block-body {
        padding: 0 10px;
        background-color: #fbfbfb;
        height: 100%;
        flex-direction: column;
        display: flex;
      }

      &.library {
        border: $border-1;
        .library-items {
          display: flex;
          flex-direction: column;
        }
        .block-body {
          padding-bottom: 0;
        }
      }

      &.layout {
        max-height: 100%;
        overflow: hidden;

        .block-body {
          height: calc(100% - 40px);
          padding: 0;
        }
      }

      &.preview {
        height: 100%;
        .block-header {
          width: 100%;
        }

        .block-body {
          width: 100%;
          height: 100%;
          display: flex;
          overflow: hidden;
        }

        .output {
          background-color: white;
          height: 100%;
          overflow: auto;
        }
      }
    }

    .dropzone-container {
      height: 100%;
      overflow: auto;
      padding: 10px;

      .dropzone {
        height: 100%;
      }
    }
  }
}
.miniview-item {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  min-width: 160px;
  display: flex;
  border-radius: 4px;
  align-items: center;
  font-size: 15px;
  background-color: white;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 1px 4px 0px rgba(40, 39, 43, 0.02),
    0px 1px 2px 0px rgba(40, 39, 43, 0.06);
  img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
}

.select-module-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;

  .modal-header {
    display: flex;
    h2 {
      font-size: 18px;
      width: 100%;
    }
  }

  .modal-body {
    margin-top: 30px;

    .miniview-item {
      margin-right: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}

.add-row-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  justify-content: center;
  color: $primary !important;
  border-radius: 4px;
  border: 1px solid rgba(0, 140, 132, 0.1) !important;
  background: rgba(25, 207, 198, 0.05) !important;
  justify-content: flex-start;
  img {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  &:hover {
    color: $primary;
    border: 1px solid rgba(0,0,0,0.2)
  }
}
</style>
<style lang="scss"></style>
