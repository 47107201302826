<template>
  <div class="other-filter filter">
    <div class="quick-actions">
      <div class="button-label">
        {{ control.name }}
      </div>

      <b-button
        class="quick-action-button"
        type="is-text"
        :disabled="loading"
        @click="selectionModel = filteredOptions">
        Select All
      </b-button>
      <b-button
        class="quick-action-button"
        type="is-text"
        :disabled="loading"
        @click="selectionModel = []">
        Deselect All
      </b-button>
    </div>
    <div class="dropdown-filter">
      <filter-multiselect
        v-model="selectionModel"
        track-by="key"
        label="value"
        :disabled="loading"
        :loading="loading"
        :icon="control.spec.icon"
        :options="dropdownOptions" />
    </div>
  </div>
</template>

<script>
  import { debounce, uniq, uniqBy } from 'lodash'

  import axios from 'axios'
  import Vue from 'vue'
  import { getReport } from '@/service/data/reportService'
  import filterMultiselect from '@/components/ui/multiselect'
  import { expressionProcessorMixin } from '@/components/reporting/expressionProcessorMixin'

  export default {
    name: 'ReportFilterItem',
    components: {
      filterMultiselect,
    },
    mixins: [expressionProcessorMixin,],
    props: {
      control: { type: Object, required: true, },
      allControls: { type: Array, required: true, },
      useBigquery: { type: Boolean, default: false, },
    },
    data () {
      return {
        unwatch: () => {},
        columns: [],
        options: [],
        internalSelection: null,
        controlIsSearch: false,
        controlSearchTerm: '',
        requestCanceler: null,

        dropdownOptions: [],
      }
    },
    computed: {
      refreshOptions () {
        return debounce(this.trueRefreshOptions, 300)
      },
      selection: {
        get () {
          let keys = (this.allSelections[this.control.id] || []).map(
            (s) => s.key
          )
          return this.enabledOptions.filter((o) => keys.includes(o.key))
        },
        set (newSelection) {
          let newSelections = { ...this.allSelections, }
          newSelections[this.control.id] = newSelection
          this.allSelections = newSelections
        },
      },
      allSelections: {
        get () {
          return this.$store.getters['controls/controlSelections']
        },
        set (selections) {
          this.$store.dispatch('controls/setSelections', selections)
        },
      },

      loading: {
        get () {
          return !!this.$store.getters['controls/loadingControls'][
            this.control.id
          ]
        },
        set (loading) {
          this.$store.dispatch(
            loading ? 'controls/startLoading' : 'controls/endLoading',
            this.control.id
          )
        },
      },
      allowMulti () {
        return !!this.control.spec.allowMulti
      },
      dateSelection () {
        return this.$store.getters['datePicker/appliedEffectiveDates']
      },
      offsetSelections () {
        return this.$store.getters['controls/offsetDates']
      },
      dateFilters () {
        let dates = this.dateSelection.map((d) => d.date_key)
        if (!dates) {
          return null
        }
        for (let control_dates of Object.values(this.offsetSelections)) {
          if (!control_dates) {
            return null
          }
          dates = [...dates, ...control_dates,]
        }
        dates = uniq(dates)
        return [
          {
            type: 'in',
            left: {
              type: 'field',
              field: {
                key: 'date',
              },
            },
            right: {
              type: 'const',
              data_type: { key: 'list', item_type: { key: 'date', }, },
              value: dates,
            },
          },
        ]
      },
      hasExclusion () {
        return (
          this.control.spec.controls.filter(
            (control) => control.type == 'dimension-exclude'
          ).length > 0
        )
      },
      exclusionControl () {
        if (this.hasExclusion) {
          return this.control.spec.controls.filter(
            (control) => control.type == 'dimension-exclude'
          )[0]
        }
        return null
      },
      excluded: {
        get () {
          if (this.allSelections[this.exclusionControl.id]) {
            return uniqBy(
              this.filteredOptions.filter((o) =>
                this.allSelections[this.exclusionControl.id]
                  .map((s) => s.key)
                  .includes(o.key)
              ),
              (s) => s.key
            )
          }
          return []
        },
        set (values) {
          let newSelects = {
            ...this.allSelections,
          }
          newSelects[this.exclusionControl.id] = values
          this.allSelections = newSelects
        },
      },
      indexedColumns () {
        return []
          .concat(
            this.control.spec.columns,
            this.control.spec.controls
              .filter((c) => c.type === 'dimension-select-filter')
              .map((control) => ({
                ...this.allControls
                  .filter((c) => control.id == c.id)
                  .slice(-1)
                  .shift()
                  .spec.columns.filter((c) => c.role == 'option_key')
                  .slice(-1)
                  .shift(),
                role: `control-${control.id}`,
                expression:
                  control.key_expression ||
                  this.allControls
                    .filter((c) => control.id == c.id)
                    .slice(-1)
                    .shift()
                    .control.spec.columns.filter((c) => c.role == 'option_key')
                    .slice(-1)
                    .shift().expression,
              }))
          )
          .map((s) => ({
            ...s,
            expression: this.preprocessExpression({
              type: 'filter',
              expression: s.expression,
              by: this.dateFilters,
            }),
          }))
          .map((s, idx) => ({ ...s, id: idx, }))
      },
      reportSpec () {
        return {
          use_bq: this.useBigquery,
          dashboard_id: this.$store.getters['dashboards/selectedDashboardId'],
          control_id: this.control.id,
          sort_by: this.control.spec.sort_by,
          series: this.indexedColumns,
        }
      },
      dimensionKeyColumns () {
        return this.columns.filter((c) => c.role === 'option_key')
      },
      dimensionColumns () {
        return this.columns.filter((c) => c.role === 'option_label')
      },
      internalKeys () {
        return (this.internalSelection || []).map((s) => s.key)
      },
      selectionModel: {
        get () {
          return this.selection || []
        },
        set (values) {
          if (this.allowMulti) {
            let availableKeys = this.enabledOptions.map((o) => o.key)
            this.internalSelection = [
              ...values,
              ...(this.internalSelection || []).filter(
                (v) => !availableKeys.includes(v.key)
              ),
            ]
            this.selection = values
          } else {
            let newVals = values.filter(
              (v) => !this.internalKeys.includes(v.key)
            )
            if (newVals.length > 0) {
              this.internalSelection = [newVals[0],]
              this.selection = [newVals[0],]
            }
          }
          this.updateFilterStatus()
        },
      },
      filteredOptions () {
        var options = this.options
        this.control.spec.controls
          .filter((control) => control.type === 'dimension-select-filter')
          .map((c) =>
            this.allControls
              .filter((a) => a.id == c.id)
              .slice(-1)
              .shift()
          )
          .forEach((control) => {
            if (this.$store.getters['controls/loadingControls'][control.id]) {
              options = []
            } else {
              var selection = new Set(
                (this.allSelections[control.id] || []).map((s) => s.key)
              )
              var column = this.columns.filter(
                (col) => col.role == `control-${control.id}`
              )
              options = options.filter((o) =>
                selection.has(this.getDimensionVal(column, o))
              )
            }
          })

        return uniqBy(options, (o) => o.key)
      },
      enabledOptions () {
        return this.filteredOptions.filter((o) => !o.disabled)
      },
    },
    watch: {
      filteredOptions (val) {
        if (this.$refs['dropdown'] && this.$refs['dropdown'].isOpen) {
          return
        }

        this.dropdownOptions = val
      },

      control () {
        this.registerSpec()
      },
      allSelections (selections, oldSelections) {
        let needsUpdate = false
        Object.keys(selections).forEach((key) => {
          if (
            (this.control.spec.controls || []).filter((c) => c.id == key).length >
            0
          ) {
            if (oldSelections[key] != selections[key]) {
              needsUpdate = true
            }
          }
        })
        if (needsUpdate) {
          this.$nextTick(() => {
            this.selectAfterChange()
          })
        }
      },
      otherControls () {
        return this.$store.controlSelections
      },
      dateSelection (filters) {
        if (filters) {
          this.loading = true
          this.refreshOptions()
        }
      },
      offsetSelections (filters, oldFilters) {
        if (filters && JSON.stringify(filters) != JSON.stringify(oldFilters)) {
          this.loading = true
          this.refreshOptions()
        }
      },
      spec (spec) {
        if (spec && !!this.dateFilters) {
          this.loading = true
          this.refreshOptions()
        }
      },
    },
    mounted () {
      this.registerSpec()
      this.unwatch = this.$store.watch(
        (_, getters) => getters['expressions/initialized'],
        () => {
          this.loading = true
          this.refreshOptions(true)
        }
      )
      this.selection = []
      this.loading = true
      this.refreshOptions(true)
    },
    destroyed () {
      this.unregisterSpec()
      this.unwatch()
    },

    methods: {
      updateFilterStatus () {
        let value = false
        if (
          this.filteredOptions.length !== 0 &&
          this.filteredOptions.length !== this.selectionModel.length
        ) {
          value = true
        }
        this.$emit('update', { value, id: this.control.id, })
      },
      openMultiselect () {
        this.$refs['dropdown'].activate()
        this.$refs['dropdown'].$el.focus()
      },
      registerSpec () {
        let newSpecs = { ...this.$store.getters['controls/controlSpecs'], }
        newSpecs[this.control.id] = this.control
        this.$store.commit('controls/setSpecs', newSpecs)
      },
      unregisterSpec () {
        let newSpecs = { ...this.$store.getters['controls/controlSpecs'], }
        delete newSpecs[this.control.id]
        this.$store.commit('controls/setSpecs', newSpecs)
      },
      onDimensionSearch () {
        this.controlIsSearch = !this.controlIsSearch
        Vue.nextTick(() => {
          if (this.$refs.controlSearch) {
            this.$refs.controlSearch.focus()
          }
        })
      },
      selectAll () {
        this.selectionModel = this.enabledOptions
      },
      deselectAll () {
        this.selectionModel = []
      },
      isExcluded (item) {
        return (
          this.hasExclusion &&
          this.excluded.filter((i) => i.key === item.key).length > 0
        )
      },
      processReport (rep) {
        this.columns = Object.freeze(
          rep.series.map((s) => ({
            ...this.indexedColumns
              .filter((c) => c.id === s.id)
              .slice(0, 1)
              .shift(),
            ...s,
          }))
        )

        this.options = Object.freeze(
          rep.data.map((o) => ({
            ...o,
            key: this.getDimensionVal(this.dimensionKeyColumns, o),
            value: this.getDimensionVal(this.dimensionColumns, o),
            disabled: this.columns.filter((c) => c.role === 'disabler').shift()
              ? !(
                o[
                  this.columns.filter((c) => c.role === 'disabler').shift().key
                ] === false
              )
              : false,
            brand_logo: this.columns
              .filter((c) => c.role == 'brand_logo')
              .map((c) => o[c.key])
              .slice(-1)
              .shift(),
          }))
        )

        this.$nextTick(() => {
          this.loading = false
        })

        this.selectAfterChange()
      },
      async trueRefreshOptions () {
        this.loading = true
        if (!this.dateFilters) {
          return
        }
        if (!this.$store.getters['expressions/initialized']) {
          return
        }
        let spec = this.reportSpec
        let companyId = this.$route.params.company_id
        try {
          if (this.requestCanceler) {
            this.requestCanceler.cancel('Canceled stale report')
          }
          this.requestCanceler = axios.CancelToken.source()

          //load new value
          const newData = await getReport(
            spec,
            companyId,
            this.requestCanceler.token
          )

          this.processReport(newData)
        } catch (e) {
          if (!!e.message && e.message != 'Canceled stale report') {
            throw e
          }
        }
      },
      getDimensionVal (dimensions, datum) {
        return dimensions.map((d) => datum[d.key]).join('–')
      },
      selectAfterChange () {
        if (this.allowMulti) {
          if (this.internalSelection == null) {
            this.selection = this.enabledOptions
          } else {
            this.selection = this.enabledOptions.filter((o) =>
              this.internalKeys.includes(o.key)
            )
          }
        } else {
          let candidates = [
            ...this.enabledOptions.filter((o) =>
              this.internalKeys.includes(o.key)
            ),
            ...this.enabledOptions,
          ].filter((o) => !o.disabled)
          if (candidates.length > 0) {
            let selected = candidates[0]
            this.selection = [selected,]
            this.internalSelection = [selected,]
          }
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.other-filter {
  background-color: white;
  border: 1px solid $gray-1;
  border-radius: 6px;
  margin: 3px;
  padding: 10px 13px;
}
.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &.filtered {
    border: 1px solid #ffb1a0;

    .icon {
      color: #ff6341 !important;
    }

    .button-value {
      color: #ff6341 !important;
    }
  }

  .icon {
    color: $gray-3;
    font-size: 18px !important;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-content {
  // border-radius: 16px !important;
}

.quick-actions {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  .button-label {
    font-size: 13px;
    margin-right: auto;
    color: $ui-02;
    font-weight: 500;
  }

  .quick-action-button {
    font-size: 10px;
    padding: 0 6px;
    height: 16px;
    color: $grayscale-3;
  }
}
</style>

<style lang="scss">
.other-filter {
  .dropdown {
    display: flex;
    width: 100%;
  }
  .dropdown-item.is-active {
    background-color: $primary !important;
    color: white !important;
  }

  .dropdown-filter {
    .multiselect__input {
      height: 30px;
    }

    .dropdown-menu {
      padding-top: 0px !important;
      // border-radius: 16px !important;
    }

    .multiselect__option {
      .selected-checkbox {
        display: none !important;
      }
      .unselected-checkbox {
        display: inline-flex !important;
      }
      .option__desc {
        display: flex;
        align-items: center;

        .option__title {
          position: relative;
          top: 1px;
        }
      }

      &.multiselect__option--selected {
        background-color: white !important;
        color: $grayscale-1 !important;

        &:hover {
          background-color: $grayscale-6;
        }
        .unselected-checkbox {
          display: none !important;
        }
        .selected-checkbox {
          display: inline-flex !important;
        }
      }
    }
  }
}
</style>
