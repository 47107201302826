<template>
  <div class="location-filter">
    <div class="quick-actions">
      <div class="button-label">
        {{ control.name }}
      </div>
      <div class="preset-label is-hidden-mobile">
        {{ timeRangeLabel }}
      </div>
    </div>
    <div class="dropdown-filter">
      <time-range-filter
        class="filter-dropdown"
        :hide-icon="true"
        :hide-secondary-label="true"
        :no-border="true"
        :default="timeRangeDefault"
        :show-secondary-label-as-primary="$screen.is('sm')"
        @submit="updateTimeRange" />
    </div>
  </div>
</template>

<script>
  import TimeRangeFilter from '@/components/controls/filter/TimeRangeFilter.vue'
  export default {
    name: 'DashboardTimeRangeFilter',
    components: {
      TimeRangeFilter,
    },
    mixins: [],
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        timeRangeLabel: '',
      }
    },
    computed: {
      control () {
        return {
          name: 'Date Range',
        }
      },
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      timeRangeDefault () {
        return {
          mode: 'calendar',
          calendarPreset: 'last_7',
        }
      },
    },
    watch: {},
    async mounted () {},
    methods: {
      updateTimeRange (data) {
        this.timeRangeLabel = data?.label?.typeLabel
        if (data.mode === 'RANGE') {
          this.$store.dispatch('datePicker/selectRangeStart', data.dates[0])
          this.$store.dispatch('datePicker/selectRangeEnd', data.dates[1])
        } else {
          this.$store.dispatch('datePicker/selectDates', data.dates)
        }
        this.$store.dispatch('datePicker/apply')

        this.dataContext.date_selection.selected_dates = this.$store.getters[
          'datePicker/appliedEffectiveDates'
        ].map((item) => item.date)
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .dropdown-menu {
  padding-top: 4px !important;
}

::v-deep .datepicker-button {
  border-radius: 0;
  height: 44px;
  padding: 8px 15px;
}

.location-filter {
  background-color: white;
  border-radius: 4px;
  width: 100%;

  @include mobile {
    display: flex;

    .dropdown-filter {
      width: 100%;
    }
  }
}
.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &.filtered {
    border: 1px solid #ffb1a0;

    .icon {
      color: #ff6341 !important;
    }

    .button-value {
      color: #ff6341 !important;
    }
  }

  .icon {
    color: $gray-3;
    font-size: 18px !important;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-content {
  border-radius: 4px !important;
}
.filter-dropdown {
  height: 44px;
  align-items: center;
  width: 100%;
  display: flex;
}

.quick-actions {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-1;
  // border-bottom: none;
  border-top: none;
  background-color: #fbfbfb;
  height: 30px;
  overflow: hidden;

  @include mobile {
    height: 44px;
    width: 180px !important;
    min-width: 180px;
    max-width: 180px;
    border: none;
    border-radius: 0;
  }

  .button-label {
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
    padding: 0 15px;
    border-right: 1px solid $gray-1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      border-radius: 0;
      justify-content: left;
      width: 300px;
    }
  }

  .preset-label {
    text-align: left;
    padding: 0 10px;
    font-size: 13px;
    color: #7e7d84;
  }

  .quick-action-button {
    height: 100%;
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
  }

  .remove-button {
    border: none;
    color: #504f54;
    background-color: transparent;
    padding: 0;
    width: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border-left: 1px solid $gray-1;

    outline: none;

    &:focus {
      outline: none;
    }

    .icon {
      font-size: 17px;
    }
  }
}
</style>
