<template>
  <header>
    <div class="top">
      <div
        v-if="dashboardIcon"
        class="logo">
        <img
          :src="dashboardIcon"
          alt="" />
      </div>
      <div class="right-section">
        <div class="report-details">
          <h1>{{ dashboardDetails?.name }}</h1>
          <!-- <p class="last-updated">
            Last Updated: Jul 11, 2024 - 9:00 AM
          </p> -->
          <button
            class="button expand-button"
            @click="$emit('toggleContainer')">
            <i class="material-icons">{{
              isContained ? "open_in_full" : "close_fullscreen"
            }}</i>
          </button>
        </div>

        <div
          v-if="dashboardDetails?.key"
          class="filters">
          <template v-for="requiredFilter in dashboardDetails.required_filters">
            <div
              v-if="requiredFilter.has_logo"
              :key="requiredFilter.id"
              class="filter">
              <location-filter
                v-model="dataContext"
                :dashboard-key="dashboardDetails.key"
                :filter-key="requiredFilter.key" />
            </div>
          </template>
          <div class="filter">
            <time-range-filter v-model="dataContext" />
          </div>
          <div
            v-for="filter in dashboardDetails.dashboard_controls"
            :key="filter.id"
            class="filter">
            <DashboardBenchmarkFilter
              v-if="filter.type === 'BenchmarkSelect'"
              v-model="dataContext"
              :filter-meta="filter" />
            <DashboardControlFilters
              v-else
              v-model="dataContext"
              :filter-meta="filter" />
          </div>
          <div
            v-if="dashboardDetails.optional_filters.length > 0"
            class="filter"
            style="overflow: hidden">
            <div class="filter-header">
              <div class="filter-label">
                Filters
              </div>
            </div>
            <div
              class="select-wrapper custom-filters-toggle"
              @click="customFiltersDropdown = !customFiltersDropdown">
              <div class="custom-filter-label">
                {{ appliedOptionalFiltersLabel || "Custom Filters" }}
              </div>
              <b-icon
                class="icon mr-5 dropdown-icon"
                :icon="customFiltersDropdown ? 'menu-up' : 'menu-down'" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="customFiltersDropdown"
      class="bottom">
      <DashboardOptionalFilters
        v-model="dataContext"
        :optional-filters="dashboardDetails?.optional_filters"
        :dashboard-key="dashboardDetails?.key" />
    </div>
  </header>
</template>

<script>
// import BenchmarkFilter from './BenchmarkFilter.vue'
  import DashboardControlFilters from './filters/DashboardControlFilters.vue'
  import DashboardBenchmarkFilter from './filters/DashboardBenchmarkFilter.vue'
  import LocationFilter from './filters/LocationFilter.vue'
  import TimeRangeFilter from './filters/DashbaordTimeRangeFilter.vue'
  import DashboardOptionalFilters from './filters/DashboardOptionalFilters.vue'

  export default {
    components: {
      TimeRangeFilter,
      DashboardBenchmarkFilter,
      DashboardOptionalFilters,
      LocationFilter,
      DashboardControlFilters,
    },
    props: {
      isContained: {
        type: Boolean,
        default: true,
      },
      dashboardDetails: {
        type: Object,
        default: () => {},
      },
      value: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        defaultDate: 'last_7_days',
        customFiltersDropdown: false,
      }
    },

    computed: {
      dashboardIcon () {
        if (
          this.dashboardDetails.required_filters.some((filter) => filter.has_logo)
        ) {
          return null
        }
        const logo =
          this.$store.getters['metadata/companyMetadata']['logo_fpath']
        return `${process.env.VUE_APP_S3_BASE_URL}/${logo}`
      },
      timeRangeDefault () {
        switch (this.defaultDate) {
          case 'today':
            return {
              mode: 'calendar',
              calendarPreset: 'today',
            }
          case 'yesterday':
            return {
              mode: 'calendar',
              calendarPreset: 'yesterday',
            }
          case 'wtd':
            return {
              mode: 'fiscal',
              fiscalPreset: 'wtd',
            }
          case 'previous_week':
            return {
              mode: 'fiscal',
              fiscalPreset: 'prev_week',
            }
          case 'last_7_days':
            return {
              mode: 'calendar',
              calendarPreset: 'last_7',
            }
          case 'last_30_days': // New time range filter only
            return {
              mode: 'calendar',
              calendarPreset: 'last_30',
            }
          case 'last_5_weeks':
            return {
              mode: 'calendar',
              calendarPreset: 'last_month',
            }
          case 'qtd':
            return {
              mode: 'fiscal',
              fiscalPreset: 'qtd',
            }
          default: // PTD
            return {
              mode: 'fiscal',
              fiscalPreset: 'ptd',
            }
        }
      },

      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      appliedOptionalFiltersLabel () {
        const selectedFilters = Object.keys(this.dataContext.filter_selections)
        if (selectedFilters.length === 0) {
          return 'None'
        }

        return this.dashboardDetails.optional_filters
          .filter((item) => selectedFilters.includes(item.key))
          .map((filter) => filter.name)
          .join(', ')
      },
    },

    methods: {},
  }
</script>
<style scoped lang="scss">

::v-deep {
  .filter-button, .custom-filters-toggle, .dropdown-trigger {
  &:hover {
    background-color: #F5F5F5 !important;
    border-radius: 0;

    .dropdown-icon {
      color: #994095;
    }
  }
}}

header {
  .top {
    display: flex !important;
  }

  border: 1px solid #d2d2d4;
  background-color: white;
  border-radius: 4px;

  .report-details {
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #d2d2d4;
    overflow: hidden;

    .expand-button {
      border-radius: 0;
      border: none;
      border-left: 1px solid #d2d2d4;
      width: 56px;
      height: 100%;

      outline: none !important;
      box-shadow: none !important;

      &:hover {
        background: #F8EEF7
      }

      i {
        font-size: 18px;
        color: #b2b1b6;
      }
    }

    h1 {
      padding: 16px;
      font-size: 20px;
      font-style: 300;
      font-weight: 700;
      line-height: 20px; /* 100% */
      letter-spacing: -0.4px;
      margin-right: auto;
      color: $ui-01;
    }

    .last-updated {
      font-size: 14px;
      padding: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 100% */
      letter-spacing: -0.28px;
      color: hsla(252, 3%, 70%, 1);
    }
  }
}

.filters {
  display: flex;
  align-items: center;
  margin: 0 !important;
  height: 74px;
  .filter {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #d2d2d4;
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: relative;
    flex: 1;

    &:last-child {
      border-right: none;
    }
  }
  .filter-header {
    font-size: 14px;
    height: 30px;
    letter-spacing: -0.28px;
    border-bottom: 1px solid #d2d2d4;
    display: flex;
    background-color: #fbfbfb;

    .filter-label {
      padding: 8px 16px;
      color: #504f54;
      font-size: 14px;
    }
    .selection {
      padding: 8px 16px;
      color: #504f54;
      font-size: 14px;
      border-left: 1px solid #d2d2d4;
    }
  }
}

.optional-filters {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 !important;
  .filter {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #d2d2d4;
    width: 100%;
    height: 100%;
    display: relative;
    flex-grow: 1;
    @include mobile {
      border: none;
    }
    &:last-child {
      border-right: none;
    }
  }
  .filter-header {
    font-size: 14px;
    height: 30px;
    letter-spacing: -0.28px;
    border-bottom: 1px solid #d2d2d4;
    display: flex;
    background-color: #fbfbfb;

    .filter-label {
      padding: 8px 16px;
      color: #504f54;
      font-size: 14px;
    }
    .selection {
      padding: 8px 16px;
      color: #504f54;
      font-size: 14px;
      border-left: 1px solid #d2d2d4;
    }
  }
}
.logo {
  border-right: 1px solid #d2d2d4;
  width: 130px;
  min-width: 130px;
  height: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d2d2d4;
  background-color: #fbfbfb;
  border-radius: 0 0 4px 4px;
}
.custom-filters-toggle {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
  overflow: hidden;
  flex: 1;
  width: 100%;
  .custom-filter-label {
    padding: 8px 16px;
    color: #504f54;
    font-size: 14px;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    flex: 1;
  }

  .icon {
    color: #504f54;
  }
}

.right-section {
  width: 100%;
}
</style>
