<template>
  <div class="filter benchmark-filter">
    <b-dropdown
      v-model="selection"
      aria-role="menu"
      min-height="auto"
      trap-focus>
      <template #trigger="{ active }">
        <a
          class="filter-button"
          role="button">
          <b-icon
            class="icon"
            icon="compare-horizontal" />
          <div class="button-content">
            <div class="button-label">Benchmark {{ selectionTopText }}</div>

            <div class="button-value">
              {{ selectionBottomText }}
            </div>
          </div>
          <b-icon
            class="icon right dropdown-icon"
            :icon="$screen.is('sm') ? 'menu-right' : active ? 'menu-up' : 'menu-down'" />

        </a>
      </template>
      <b-dropdown-item
        v-for="option in options"
        :key="option.key"
        class="dropdown-item"
        :value="option"
        aria-role="listitem">
        {{ option.value }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import controlSelectorMixin from '@/components/controls/controlSelectorMixin'
  import * as moment from 'moment'
  import { mapGetters } from 'vuex'

  export default {
    name: 'BenchmarkFilter',
    components: {},
    mixins: [controlSelectorMixin,],
    data () {
      return {
        watcher: null,
        datePickerStartDate: null,
        datePickerEndDate: null,

        showDateRangeFor: [
          'previous_day',
          'previous_week',
          'previous_period',
          'previous_quarter',
          'previous_year',
        ],
      }
    },
    computed: {
      ...mapGetters({
        offsetDates: 'controls/offsetDates',
      }),

      options () {
        return this.control.spec.options.filter(this.isOffsetViable)
      },

      selectionDateRange () {
        return this.offsetDates[this.control.id]
      },
      selectionBottomText () {
        if (!this.selection) return 'none'

        //show time rage for previous day, week, quarter, year
        if (
          this.showDateRangeFor.includes(this.selection.key) &&
          this.selectionDateRange.length > 0
        ) {
          const startDate = moment(this.selectionDateRange[0]).format('ll')

          const endDate =
            this.selectionDateRange.length > 1
              ? moment(this.selectionDateRange.slice(-1)[0]).format('ll')
              : null

          return `${startDate} ${endDate ? ` to ${endDate}` : ''}`
        }

        //show label for all other options
        return this.selection.value
      },

      selectionTopText () {
        if (!this.selection) return ''

        return this.showDateRangeFor.includes(this.selection.key)
          ? `- ${this.selection.value}`
          : ''
      },
    },
    mounted () {
      this.selection = [
        ...this.control.spec.options.filter(
          (o) => o.is_default && this.isOffsetViable(o)
        ),
        ...this.control.spec.options.filter(this.isOffsetViable),
        ...this.control.spec.options,
      ][0]
      this.watcher = this.$store.watch((state, getters) => {
        //rewrite this with store map getters
        let start = new Date(getters['datePicker/appliedEffectiveRangeStart'])
        let end = new Date(getters['datePicker/appliedEffectiveRangeEnd'])

        return (end - start) / (1000 * 60 * 60 * 24) + 1
      }, this.onDateChange)
    },
    beforeDestroy () {
      this.watcher()
    },

    methods: {
      onDateChange (duration) {
        if (!!this.selection && this.selection.max_duration < duration) {
          this.selection = [
            ...this.control.spec.options.filter(
              (o) => o.is_default && this.isOffsetViable(o)
            ),
            ...this.control.spec.options.filter(this.isOffsetViable),
            ...this.control.spec.options,
          ][0]
        }
      },
      isOffsetViable (offsetOption) {
        if (this.$store.getters['datePicker/loading']) {
          return true
        }
        let start = new Date(
          this.$store.getters['datePicker/appliedEffectiveRangeStart']
        )
        let end = new Date(
          this.$store.getters['datePicker/appliedEffectiveRangeEnd']
        )
        let durationDays = (end - start) / (1000 * 60 * 60 * 24) + 1
        return offsetOption.max_duration >= durationDays
      },
    },
  }
</script>

<style lang="scss" scoped>
.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;

  .icon {
    color: $gray-3;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;

    .button-label {
      font-size: 10px;
      color: $gray-4;
      text-transform: capitalize;
      margin-bottom: 2px;
    }
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      // max-width: 150px;
      width: 100%;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-item {
  width: 250px !important;
}
</style>
<style lang="scss">
.benchmark-filter {
  .dropdown {
    display: flex;
    width: 100%;
  }
  .dropdown-item.is-active {
    background-color: $primary !important;
    color: white !important;
  }
}
</style>
