<template>
  <div
    :key="tabs.length"
    class="dashboard-tabs-container">
    <div
      v-show="tabs?.length > 1"
      class="dashboard-tabs is-hidden-mobile">
      <div
        v-for="(tab, i) in tabs"
        :key="i"
        :class="{ active: currentTab === tab.id }"
        class="tab"
        @click="selectTab(tab.id)">
        <div
          v-if="tab.icon"
          class="icon-container">
          <i class="material-icons">{{ tab.icon }}</i>
        </div>
        <div class="tab-label">
          {{ tab.label }}
        </div>
      </div>
    </div>
    <b-dropdown
      v-model="currentTab"
      class="mobile-dropdown is-hidden-tablet">
      <template #trigger>
        <div class="dropdown-trigger px-5">
          <div class="mobile-section-label">
            <span class="secondary-label"> Section </span>
            <div class="tab-label">
              {{ getCurrentTabLabel() }}
            </div>
          </div>

          <i class="material-icons">arrow_right</i>
        </div>
      </template>

      <b-dropdown-item
        v-for="(tab, i) in tabs"
        :key="i"
        :value="tab.id">
        <div class="dropdown-item-content">
          <!-- <i
            v-if="tab.icon"
            class="material-icons">{{ tab.icon }}</i> -->
          <span>{{ tab.label }}</span>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  export default {
    props: {
      tabs: {
        type: Array,
        default: () => [],
      },

      value: {
        type: String,
        default: '',
      },
    },
    data () {
      return {}
    },
    computed: {
      currentTab: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {
      selectTab (tab) {
        this.currentTab = tab
      },
      getCurrentTabLabel () {
        const currentTab = this.tabs.find((tab) => tab.id === this.currentTab)
        return currentTab ? currentTab.label : 'Select Tab'
      },
      getCurrentTabIcon () {
        const currentTab = this.tabs.find((tab) => tab.id === this.currentTab)
        return currentTab ? currentTab.icon : null
      },
    },
  }
</script>
<style lang="scss" scoped>
.dashboard-tabs {
  display: flex;
  width: 100%;
}

::v-deep .dropdown-content {
  overflow: hidden;
}

.dashboard-tabs-container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}
.tab {
  width: 100%;
  border-radius: 0;
  background-color: $ui-09;
  border-right: 1px solid #e0e0e0;
  &:last-child {
    border-right: none;
  }

  border-top: none;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $ui-08;
    .icon-container {
      border-right: 1px solid $ui-06;
      i {
        color: #b654b1;
      }
    }
  }
  &.active {
    background-color: #28272b;
    color: white;

    .icon-container {
      border-color: #504f54;
      i {
        color: white;
      }
    }
  }
}

.icon-container {
  display: flex;
  align-items: center;
  height: 40px;
  width: 42px;
  justify-content: center;
  border-right: 1px solid $ui-07;
  i {
    font-size: 16px;
    color: #d2d2d4;
  }
}

.tab-label {
  padding-left: 10px;
  @include mobile {
    padding: 0;
  }
}

.mobile-dropdown {
  width: 100%;

  :deep(.dropdown-item.is-active) {
    background-color: rgb(233, 233, 234);
    color: rgb(74, 74, 74)
  }

  .dropdown-trigger {
    width: 100%;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background-color: white;
    color: $grayscale-1;
    .mobile-section-label {
      display: flex;
      flex-direction: column;
      width: 100%;

      .secondary-label {
        font-size: 12px;
        font-weight: 300;
        color: $grayscale-4;
      }
    }
    .tab-label {
      font-size: 16px;
      width: 100%;
      align-items: center;
    }

    i {
      font-size: 22px;
      color: $grayscale-4;
    }
  }

  .dropdown-item-content {
    display: flex;
    align-items: center;

    i {
      margin-right: 10px;
    }
  }
}
</style>
