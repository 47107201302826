<template>
  <div class="container">
    <div class="title">
      <h2>Reports</h2>
      <p>
        {{ navlinks.reports.description }}
      </p>
    </div>
    <div class="search-container">
      <div class="search-input">
        <b-input
          v-model="reportSearchInput"
          custom-class="no-border no-shadow"
          placeholder="Search..."
          type="search"
          icon="magnify" />
      </div>
    </div>

    <div class="columns is-multiline">
      <div
        v-for="(report, i) in resultsToShow"
        :key="i"
        class="column is-12-mobile is-6-tablet is-4-desktop is-3-widescreen"
        :class="{ 'is-depricated': report.isDepricated }">
        <router-link
          class=""
          :to="report.route">
          <div class="report-card">
            <div class="header">
              <div class="icon">
                <i class="material-icons item-icon">
                  {{ report.icon }}
                </i>
              </div>
              <div class="name">
                {{ report.name }}
              </div>
            </div>
            <div class="desc">
              {{ report.description || "No Description" }}
            </div>

            <!-- <div class="last-updated">
                Updated 3mins ago
              </div> -->
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import FuzzySearch from 'fuzzy-search'
  import navigation from '@/mixins/navigation'
  export default {
    name: 'ReportsHome',
    components: {},
    mixins: [navigation,],
    data () {
      return { reportSearchInput: '', }
    },
    computed: {
      searcher () {
        const searcher = new FuzzySearch(this.allReports, ['name',])
        return searcher
      },

      resultsToShow () {
        if (this.reportSearchInput) {
          return this.searcher.search(this.reportSearchInput)
        }
        return this.allReports
      },
    },
  }
</script>
<style lang="scss" scoped>
.search-container {
  background-color: white;
  padding: 6px;
  border: $border-1;
  border-radius: 6px;
  margin-bottom: 20px;
}

.is-depricated {
  @include mobile {
    display: none !important;
  }
}

.search-input {
  border: none;
  border-radius: 6px;
  margin: 0px;
}
.container {
  padding: 40px 20px;
  @include mobile {
    padding: 30px 10px 50px;
  }
  .title {
    h2 {
      font-size: 20px;
      margin-bottom: 8px;
      font-weight: 700;
      color: $ui-01;
      @include mobile {
        font-size: 24px;
        margin-bottom: 10px;
      }
    }

    p {
      font-size: 14px;
      color: $ui-03;
      font-weight: 300;
      margin-bottom: 30px;
      @include mobile {
        font-size: 16px;
        margin-bottom: 30px;
      }
    }
  }
}
.report-card {
  border: $border-1;
  border-radius: 9px;
  font-size: 14px;
  padding: 12px;
  transition: all 0.3s;
  height: 100%;
  @include mobile {
    font-size: 16px;
    padding: 15px;
  }

  &:hover {
    background-color: white;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
    border-color: $primary;

    .icon {
      color: $primary !important;
    }
  }
  .header {
    display: flex;
    color: $grayscale-1;

    .name {
      font-size: 16px;
      text-transform: capitalize;
      font-weight: 500;
      color: #28272b;
      margin-top: 3px;
      @include mobile {
        font-size: 18px;
      }
    }

    .icon {
      height: 30px;
      width: 30px;
      border-radius: 5px;
      display: block;
      border: $border-1;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      overflow: hidden;
      align-items: center;
      color: #b2b1b6;
      transition: color 0.3s;
      @include mobile {
        height: 35px;
        width: 35px;
        margin-right: 10px;
      }
    }
  }

  .desc {
    font-size: 14px;
    color: #7e7d84;
    margin-top: 10px;
    font-weight: 400;
    line-height: 18px;
    @include mobile {
      font-size: 16px;
      margin-top: 14px;
      line-height: 20px;
    }
  }

  .last-updated {
    font-size: 12px;
    color: $grayscale-4;
    margin-top: 10px;
    @include mobile {
      font-size: 14px;
      margin-top: 14px;
    }
  }
}
</style>
