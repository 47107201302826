<template>
  <div class="other-filter">
    <div class="quick-actions">
      <div class="button-label">
        {{ filterMeta?.name }}
      </div>
      <b-button
        class="quick-action-button"
        type="is-text"
        :disabled="loading"
        @click.stop="filterChange(null)">
        All
      </b-button>
      <b-button
        class="quick-action-button"
        type="is-text"
        :disabled="loading"
        @click.stop="filterChange([])">
        None
      </b-button>

      <!-- <button class="button remove-button">
        <i class="material-icons icon">close</i>
      </button> -->
    </div>
    <div class="dropdown-filter">
      <filter-multiselect
        v-model="selectedValues"
        track-by="key"
        label="label"
        :disabled="loading"
        :mobile-modal="false"
        :loading="loading"
        :options="dropdownOptions" />
    </div>
  </div>
</template>

<script>
  import filterMultiselect from '@/components/ui/multiselect'

  export default {
    name: 'CustomFilter',
    components: {
      filterMultiselect,
    },
    mixins: [],
    props: {
      filterMeta: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
      value: {
        type: null || Array,
        required: true,
        default: () => null,
      },
      fulldataContext: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        loading: false,
        dropdownOptions: [],
      }
    },
    computed: {
      selectedValues: {
        get () {
          return this.value === null ? this.dropdownOptions : this.value
        },
        set (newValue) {
          this.$emit(
            'input',
            newValue?.length === this.dropdownOptions?.length ? null : newValue
          )

          if (!this.loading) {
            console.log('makeInputsDirty')
            this.$emit('makeInputsDirty')
          }
        },
      },
    },
    watch: {},
    async mounted () {
      this.loading = true
      this.dropdownOptions =
        await this.$apis.companyConfigs.getDashboardFilterOptions(
          this.dashboardKey,
          this.filterMeta.key,
          this.fulldataContext
        )

      this.selectedValues = this.dropdownOptions
      await this.$nextTick()
      this.loading = false
    },

    methods: {
      filterChange (values) {
        this.selectedValues = values
      },
    },
  }
</script>
<style lang="scss" scoped>
.other-filter {
  background-color: white;
  border: 1px solid $gray-1;
  border-radius: 4px;
  width: 100%;
}

::v-deep .filter-button {
  border: 1px solid transparent;
  border-radius: 0px 0px 3px 3px !important;
  width: 100%;
  cursor: pointer;
}

.dropdown-content {
  border-radius: 4px !important;
}

.quick-actions {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-1;
  border-radius: 4px 4px 0 0;
  border-top: none;
  background-color: #fbfbfb;
  height: 30px;
  overflow: hidden;

  .button-label {
    font-size: 13px;
    margin-right: auto;
    color: #504f54;
    font-weight: 200;
    padding: 0 15px;
    border-right: 1px solid $gray-1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quick-action-button {
    height: 100%;
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
    border-radius: 0;
    box-shadow: none !important;
  }

  .remove-button {
    border: none;
    color: #504f54;
    background-color: transparent;
    padding: 0;
    width: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border-left: 1px solid $gray-1;

    outline: none;

    &:focus {
      outline: none;
    }

    .icon {
      font-size: 17px;
    }
  }
}
</style>
