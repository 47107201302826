<template>
  <div class="sub-section">
    <b-field
      label="Time Range"
      class="field">
      <TimeRangeEditor
        v-model="inputs"
        :optional="true" />
    </b-field>
  </div>
</template>

<script>
  import TimeRangeEditor from '@/components/reports/config/modules/common/TimeRangeEditor'

  export default {
    name: 'TableTimeRange',
    components: {
      TimeRangeEditor,
    },
    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      inputs: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
