<template>
  <div class="dropdown-filter">
    <b-dropdown
      ref="filter"
      aria-role="menu"
      expanded
      position="is-bottom-right"
      :mobile-modal="mobileModal"
      min-height="auto"
      :loading="loading"
      :disabled="disabled">
      <template #trigger="{ active }">
        <div
          class="filter-button"
          :class="{ filtered: isFiltered }"
          role="button">
          <i
            v-if="icon"
            class="material-icons icon">
            {{ icon }}
          </i>
          <div class="button-content">
            <div
              v-if="loading"
              class="loading-message">
              Loading
            </div>
            <div
              v-else
              class="button-value">
              {{ selection.length }} of {{ options.length }} Selected
            </div>
          </div>
          <b-icon
            class="icon right dropdown-icon"
            :icon="
              $screen.is('sm') ? 'menu-right' : active ? 'menu-up' : 'menu-down'
            " />
        </div>
      </template>

      <b-dropdown-item
        aria-role="menu-item"
        custom
        paddingless>
        <form>
          <input
            v-model="searchInput"
            class="input search-input"
            placeholder="Search" />
          <div class="dropdown-content-container">
            <div
              v-for="(option, i) in resultsToShow"
              :key="i"
              class="dropdown-option"
              @click="toggleOption(option)">
              <b-checkbox
                :value="optionSelectionMap[option[trackBy]]"
                class="selected-checkbox"
                @click.prevent />

              {{ option[label] }}
            </div>

            <div
              v-if="searchInput && resultsToShow.length === 0"
              class="no-results">
              No results
            </div>
          </div>
        </form>
      </b-dropdown-item>
      <div
        v-if="searchInput && resultsToShow.length > 0"
        class="footer-actions">
        <button
          class="button"
          @click="selectAllResults(true)">
          Select all Results
        </button>
        <button
          class="button"
          @click="selectAllResults(false)">
          Deselect all Results
        </button>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
  import FuzzySearch from 'fuzzy-search'

  export default {
    name: 'MultiSelect',
    props: {
      loading: { type: Boolean, default: false, },
      disabled: { type: Boolean, default: false, },
      options: { type: Array, required: true, },
      value: { type: Array, required: true, },
      mobileModal: { type: Boolean, default: true, },
      icon: { type: String, default: '', },

      label: { type: String, default: 'name', },
      trackBy: { type: String, default: 'id', },
    },
    data () {
      return {
        searchInput: '',
      }
    },

    computed: {
      searcher () {
        const searcher = new FuzzySearch(this.options, [this.label,])
        return searcher
      },

      resultsToShow () {
        if (this.searchInput) {
          return this.searcher.search(this.searchInput)
        }
        return this.options
      },

      isFiltered () {
        if (
          this.options.length !== 0 &&
          this.options.length !== this.selection.length
        ) {
          return true
        }
        return false
      },

      selection () {
        return this.value
      },

      optionSelectionMap () {
        const map = {}
        this.value.forEach((item) => {
          map[item[this.trackBy]] = true
        })
        return map
      },
    },

    methods: {
      selectAllResults (value) {
        this.resultsToShow.forEach((item) => {
          this.$set(this.optionSelectionMap, item[this.trackBy], value)
        })

        this.$emit(
          'input',
          this.options.filter(
            (item) => this.optionSelectionMap[item[this.trackBy]]
          )
        )
      },
      toggleOption (option) {
        const itemId = option[this.trackBy]
        const currentValue = this.optionSelectionMap[itemId]

        this.$set(this.optionSelectionMap, itemId, !currentValue)

        this.$emit(
          'input',
          this.options.filter(
            (item) => this.optionSelectionMap[item[this.trackBy]]
          )
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
.dropdown-option {
  padding: 10px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $grayscale-7;
    cursor: pointer;
  }
}
.footer-actions {
  display: flex;
  padding: 4px;
  button {
    font-size: 12px;
    margin: 2px;
    width: 100%;
  }
}
.selected-checkbox {
  pointer-events: none;
}
.search-input {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;

  border-bottom: $border-1 !important;
  border-radius: 0;

  &:focus {
    box-shadow: none !important;
  }
}

.dropdown-content-container {
  max-height: 400px;
  overflow-y: auto;
}

.no-results {
  text-align: center;
  padding: 10px;
  opacity: 0.8;
}

.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &.filtered {
    border: 1px solid #ffb1a0;

    .icon {
      color: #ff6341 !important;
    }

    .button-value {
      color: #ff6341 !important;
    }
  }

  .icon {
    color: $gray-3;
    font-size: 18px !important;
    margin-right: 10px;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}
</style>
