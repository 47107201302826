<template>
  <div class="other-filter">
    <div class="quick-actions">
      <div class="button-label">
        {{ filterMeta?.name }}
      </div>
      <div class="secondary-value" />
    </div>
    <div class="dropdown-filter">
      <singleSelect
        v-model="selectionModel"
        track-by="key"
        :no-border="true"
        label="label"
        :disabled="loading"
        :loading="loading"
        :options="dropdownOptions"
        @input="updateSelectionModel" />
    </div>
  </div>
</template>

<script>
  import singleSelect from '@/components/ui/singleselect'

  export default {
    name: 'DashboardControlFilters',
    components: {
      singleSelect,
    },
    mixins: [],
    props: {
      filterMeta: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectionModel: null,
        loading: false,
        dropdownOptions: [],
      }
    },
    computed: {
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    watch: {},
    async mounted () {
      this.dropdownOptions = this.filterMeta.options
      this.updateSelectionModel(this.dropdownOptions[0])
      this.selectionModel = this.dropdownOptions[0]
    },

    methods: {
      updateSelectionModel (value) {
        this.$set(this.dataContext.control_selections, this.filterMeta.key, {
          type: this.filterMeta.type,
          selected_option: value.key,
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.other-filter {
  background-color: white;
  border-radius: 4px;
  width: 100%;
  border: 0.2px solid $gray-1;
  @include mobile {
    display: flex;
    border: none;
    .dropdown-filter {
      width: 100%;
    }
  }
}
.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &.filtered {
    border: 1px solid #ffb1a0;

    .icon {
      color: #ff6341 !important;
    }

    .button-value {
      color: #ff6341 !important;
    }
  }

  .icon {
    color: $gray-3;
    font-size: 18px !important;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-content {
  border-radius: 4px !important;
}

.secondary-value {
  font-size: 13px;
  color: #504f54;
  font-weight: 200;
  padding: 0 15px;

  @include mobile {
    display: none;
  }
}

.quick-actions {
  display: flex;
  align-items: center;
  // border: 1px solid $gray-1;
  // border-bottom: none;
  border-bottom: 1px solid $gray-1;
  border-top: none;
  background-color: #fbfbfb;
  height: 44px;
  overflow: hidden;

  @include mobile {
    height: 44px;
    width: 180px !important;
    min-width: 180px;
    max-width: 180px;
    border: none;
    border-radius: 0;
  }

  .button-label {
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
    padding: 0 15px;
    border-right: 1px solid $gray-1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
      border-radius: 0;
      justify-content: left;
      border-right: 1px solid $grayscale-6;
    }
  }

  .quick-action-button {
    height: 100%;
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
  }

  .remove-button {
    border: none;
    color: #504f54;
    background-color: transparent;
    padding: 0;
    width: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border-left: 1px solid $gray-1;

    outline: none;

    &:focus {
      outline: none;
    }

    .icon {
      font-size: 17px;
    }
  }
}
</style>
